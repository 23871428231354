import React from 'react';
import PropTypes from 'prop-types';
import {Button as BaseButton} from 'reactstrap';

const Button = ({
    type,
    size,
    color,
    block,
    disabled,
    onClick,
    children
}) => (
    <BaseButton
        type={type}
        size={size}
        color={color}
        block={block}
        disabled={disabled}
        onClick={onClick}
    >
        {children}
    </BaseButton>
)

Button.propTypes = {
    type: PropTypes.oneOf(['button', 'submit']),
    size: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light-blue']),
    block: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node
}

export default Button;