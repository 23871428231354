import { combineReducers } from 'redux';
import appointments from './appointments/reducers';
import auth from './auth/reducers';
import clients from './clients/reducers';
import clientTypes from './clientTypes/reducers';
import dashboardMessages from './dashboardMessages/reducers';
import discounts from './discounts/reducers';
import documentReads from './documentReads/reducers';
import feedbackQuestions from './feedbackQuestions/reducers';
import glossary from './glossary/reducers';
import keynestStores from './keynestStores/reducers';
import notifications from './notifications/reducer';
import postcodes from './postcodes/reducers';
import properties from './properties/reducers';
import returnArrangements from './returnArrangements/reducers';
import sms from './sms/reducers';
import smsUsage from './smsUsage/reducers';
import users from './users/reducers';
import userActions from './userActions/reducers';
import viewberBillingTypes from './viewberBillingTypes/reducers';
import viewingPacks from './viewingPacks/reducers';
import viewingPacksAvailable from './viewingPacksAvailable/reducers';
import viewingDateTypes from './viewingDateTypes/reducers';
import viewingLengths from './viewingLengths/reducers';
import viewings from './viewings/reducers';
import viewingTypes from './viewingTypes/reducers';
import viewingsUpcoming from './viewingsUpcoming/reducers';

export default combineReducers({
	appointments,
    auth,
    clients,
	clientTypes,
	dashboardMessages,
	discounts,
	documentReads,
	feedbackQuestions,
	glossary,
    keynestStores,
    notifications,
    postcodes,
    properties,
    returnArrangements,
    sms,
    smsUsage,
    users,
    userActions,
    viewberBillingTypes,
	viewingPacks,
	viewingPacksAvailable,
	viewingDateTypes,
	viewingLengths,
	viewings,
	viewingTypes,
	viewingsUpcoming,
});
