import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { Container } from 'reactstrap';

import {
    AppFooter,
    AppHeader,
    AppSidebar,
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader,
    AppSidebarMinimizer,
    AppSidebarNav
} from '@coreui/react';

import ErrorBoundary from '../../ErrorBoundary';
import MaintenanceChecker from '../../MaintenanceChecker';
import LoggingOutCard from '../../components/Cards/LoggingOutCard';
import RestrictedComponent from '../../components/RestrictedComponent';
import DocumentsReadRestrictedComponent from '../../components/DocumentsReadRestrictedComponent';
import WaitingComponent from '../../components/WaitingComponent';
import { Page404 } from '../../views/Pages';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import navigation from '../../_nav';
import routes from '../../routes';
import Breadcrumbs from './Breadcrumbs';
import { Button } from '../../components';
import { ChoosePropertyModal } from '../../components/Modals/ChoosePropertyModal';
import ErrorReportingContext from '../../ErrorReportingContext';
import ClientBanner from "../../components/ClientBanner";

const superManagerServices = [
    'Third party services',
    'Phone Pin',
    'Feedback Questions',
    'User Management',
    'Invoice Settings',
]

const DefaultLayout = ({ history, location, logging_out, user }) => {
    const [choosePropertyModalIsOpen, setChoosePropertyModalIsOpen] = useState(false);

    const toggleChoosePropertyModal = () => {
        setChoosePropertyModalIsOpen(!choosePropertyModalIsOpen);
    };

    const openAffiliatesLink = () => {
        window.open('https://viewber.co.uk/about-viewber/affiliates', '_blank');
    };

    const navConfig = cloneDeep(navigation);

    navConfig.items = navConfig.items.map(navItem => {
        if (navItem.name === 'Viewing Packs' && !get(user, 'client.viewing_packs_count')) {
            return null;
        }

        if (navItem.name === 'Reapit' && !get(user, 'client.reapit_details')) {
            return null;
        }

        if (navItem.name === 'Reports' && !get(user, 'client.client_settings.enable_report_export')) {
            return null;
        }

        if (navItem.name === 'Invoices' && (!get(user, 'client.pre_pay') && !user?.super_manager)) {
            return null;
        }

        if (!user?.super_manager) {
            if (superManagerServices.includes(navItem.name)) {
                return null;
            }

            if (navItem.children) {
                navItem.children = navItem.children.filter(child => !superManagerServices.includes(child.name));

                if (navItem.children.length === 0) {
                    return null;
                }
            }
        }

        return navItem;
    }).filter(Boolean);

    return (
        <React.Fragment>
            <div className="app">
                <AppHeader fixed>
                    <DefaultHeader history={history} />
                </AppHeader>
                <div className="app-body">
                    <AppSidebar fixed display="lg">
                        <AppSidebarHeader />
                        <AppSidebarForm />
                        <AppSidebarNav
                            location={location}
                            navConfig={navConfig}
                            staticContext={null} // Since this is a functional component, staticContext can be set to null
                        />
                        <AppSidebarFooter />
                        <AppSidebarMinimizer />
                    </AppSidebar>
                    <main className="main">
                        <Breadcrumbs
                            appRoutes={routes}
                            rightContent={
                                <React.Fragment>
                                    <Button
                                      type="button"
                                      color="warning"
                                      size="sm"
                                        onClick={() => openAffiliatesLink()}
                                    >
                                        <span>Refer a Client</span>
                                    </Button>
                                    <span className={'ml-2'} />
                                    <Button
                                      type="button"
                                      color="light-blue"
                                      size="sm"
                                      onClick={() => setChoosePropertyModalIsOpen(true)}
                                    >
                                        <i className="fa fa-plus" />
                                        <span className="ml-2">Book appointment</span>
                                    </Button>
                                </React.Fragment>
                            }
                        />
                        <Container fluid>
                            <LoggingOutCard loggingOut={logging_out} />
                            <Switch>
                                {routes.map((route, idx) => (
                                    route.component ? (
                                        <Route
                                            key={idx}
                                            path={route.path}
                                            exact={true}
                                            name={route.name}
                                            render={props => (
                                                <ErrorBoundary>
                                                    <ErrorReportingContext context={route.path}>
                                                        <RestrictedComponent>
                                                            <MaintenanceChecker>
                                                                <DocumentsReadRestrictedComponent>
                                                                    <WaitingComponent
                                                                        Component={route.component}
                                                                        query={queryString.parse(location.search)}
                                                                        {...props}
                                                                    />
                                                                </DocumentsReadRestrictedComponent>
                                                            </MaintenanceChecker>
                                                        </RestrictedComponent>
                                                    </ErrorReportingContext>
                                                </ErrorBoundary>
                                            )}
                                        />
                                    ) : null
                                ))}
                                <Route name="Page 404" component={Page404} />
                            </Switch>
                        </Container>
                    </main>
                </div>
                <AppFooter>
                    <DefaultFooter />
                </AppFooter>
                <ClientBanner />
            </div>
            <ChoosePropertyModal
                isOpen={choosePropertyModalIsOpen}
                toggle={toggleChoosePropertyModal}
                onBookNow={property => {
                    setChoosePropertyModalIsOpen(false);
                    history.push(`/properties/${property.id}/appointments/create`);
                }}
                onCreateNewProperty={() => {
                    setChoosePropertyModalIsOpen(false);
                }}
            />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const { logging_out, user } = state.auth;
    const { unreadNotificationCounts } = state.notifications;

    return {
        user,
        logging_out: logging_out === true,
        unreadNotificationCounts: unreadNotificationCounts,
    };
};

export default connect(mapStateToProps)(DefaultLayout);