import sagaRegistry from './sagaRegistry';
import appointments from './appointments/sagas';
import auth from './auth/sagas';
import clients from './clients/sagas';
import dashboardMessages from './dashboardMessages/sagas';
import discounts from './discounts/sagas';
import clientTypes from './clientTypes/sagas';
import documentReads from './documentReads/sagas';
import feedbackQuestions from './feedbackQuestions/sagas';
import glossary from './glossary/sagas';
import keynestStores from './keynestStores/sagas';
import postcodes from './postcodes/sagas';
import properties from './properties/sagas';
import returnArrangements from './returnArrangements/sagas';
import sms from './sms/sagas';
import smsUsage from './smsUsage/sagas';
import users from './users/sagas';
import userActions from './userActions/sagas';
import viewberBillingTypes from './viewberBillingTypes/sagas';
import viewingDateTypes from './viewingDateTypes/sagas';
import viewingPacks from './viewingPacks/sagas';
import viewingPacksAvailable from './viewingPacksAvailable/sagas';
import viewingLengths from './viewingLengths/sagas';
import viewingTypes from './viewingTypes/sagas';
import viewings from './viewings/sagas';
import viewingsUpcoming from './viewingsUpcoming/sagas';

// We only register the initial default sagas here,
// Leaving the other ones for async loading
const defaultSagas = {
	appointments,
    auth,
    clients,
    clientTypes,
	dashboardMessages,
	discounts,
    documentReads,
	feedbackQuestions,
	glossary,
    keynestStores,
    postcodes,
    properties,
    returnArrangements,
    sms,
    smsUsage,
    users,
    userActions,
    viewberBillingTypes,
	viewingLengths,
	viewings,
	viewingDateTypes,
	viewingPacks,
	viewingPacksAvailable,
	viewingTypes,
    viewingsUpcoming,
};

const registerDefaultSagas = () => {
    Object.entries(defaultSagas).forEach(([name, saga]) => {
        sagaRegistry.register(name, saga);
    });
};

export default registerDefaultSagas;
