import * as viewingsAPI from '../index';
import {useQuery} from '@tanstack/react-query'

const queryKey = (viewingId, token) => [token, 'viewings', viewingId, 'feedback-key-holding'];

export const useViewingFeedbackKeyholding = (
    viewingId,
    token,
    options
) => {
    const {
        data,
        isLoading,
        isError,
        error,
        refetch,
    } = useQuery(
        queryKey(viewingId, token),
        () => new Promise((resolve, reject) => {
            viewingsAPI.appointmentFeedbackKeyHolding({id: viewingId, token})
            .then(res => resolve(res.data.resource))
            .catch(reject);
        }),
        options
    );

  return {
    feedbackKeyHolding: data,
    isLoading,
    isError,
    error,
    refetch,
  }
}